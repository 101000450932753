import AxiosHelper from "./AxiosHelper";

//expose a public function to call the REST apis from the application
export default function callAPI(requestObject: object) {
  return AxiosHelper(requestObject)
    .then((response) => {
      return {
        status: 200,
        response: response.data,
        responseHeaders: response.headers,
      };
    })
    .catch((error) => {
      return error;
    });
}
