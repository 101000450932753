import { MediaStreamConstraints } from "../Types/MediaTypes";

// Fetch an array of devices of a certain type
export async function getConnectedDevices(type: string) {
  let canEnumerate = false;
  if (
    typeof MediaStreamTrack !== "undefined" &&
    "getSources" in MediaStreamTrack
  ) {
    canEnumerate = true;
  } else if (
    navigator.mediaDevices &&
    !!navigator.mediaDevices?.enumerateDevices
  ) {
    canEnumerate = true;
  }
  if (
    canEnumerate &&
    navigator.mediaDevices &&
    navigator.mediaDevices?.enumerateDevices
  ) {
    let devices = await navigator.mediaDevices?.enumerateDevices();

    const speakers = await getSpeakers();
    if (speakers) {
      devices.push(speakers);
    }
    console.log("Media Devices: ", devices);
    return devices?.filter((device) => device?.kind === type);
  }
}

export const getUserMedia = (
  constraints: MediaStreamConstraints
): Promise<MediaStream> => {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      reject(new MediaError());
      return;
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (stream !== undefined) {
          // Done to resolve firefox issue where enumerate devices requires tracks to be ON (#156).
          setTimeout(() => {
            try {
              stream.getTracks().forEach((track: any) => {
                track.stop();
              });
            } catch (exception) {
              console.log("Error while stopping the tracks", exception);
            }
          }, 4000);
        }
        resolve(stream);
      })
      .catch((error) => {
        console.log("error: ", error);
        reject(new MediaError());
      });
  });
};

// Fix for firefox isue where speakers were not listed using enumerate devices
export const getSpeakers = async () => {
  // @ts-ignore
  if (!navigator.mediaDevices.selectAudioOutput) {
    console.log("selectAudioOutput() not supported.");
    return;
  }
  let speakers: MediaDeviceInfo = {
    deviceId: "",
    groupId: "",
    kind: "audioinput",
    label: "",
    toJSON: function () {
      throw new Error("Function not implemented.");
    },
  };
  // Display prompt and log selected device or error
  await navigator.mediaDevices
    // @ts-ignore
    .selectAudioOutput()
    .then((device: any) => {
      speakers = device;
      console.log(
        `Speakers=${device.kind}: ${device.label} id = ${device.deviceId}`
      );
    })
    .catch((err: any) => {
      console.error(`${err.name}: ${err.message}`);
    });
  return speakers;
};

/**
 * Apply the selected audio output device.
 * @param {string} deviceId
 * @param {HTMLAudioElement} audio
 * @returns {Promise<void>}
 */
// NOTE: safari does not let us query the output device (and its HTMLAudioElement does not have setSinkId)
export async function applyAudioOutputDeviceSelection(
  deviceId: string,
  audio: any
) {
  return typeof audio?.setSinkId === "function"
    ? await audio?.setSinkId(deviceId)
    : Promise.reject(
        "This browser does not support setting an audio output device"
      );
}

// reads selected audio output, and updates preview to use the device.
/*function applyAudioOutputDeviceChange() {
  const deviceId = deviceSelections?.audiooutput.value;
  document.querySelectorAll('.remote-audio').forEach(audioEl: HTMLAudioElement => {
    if (deviceId) {
      // Note: not supported on safari
      applyAudioOutputDeviceSelection(deviceId, audioEl)
        .then(() => {
          console.log(`Success, audio output device attached: ${deviceId}`);
        })
        .catch(error => {
          let errorMessage = error;
          console.error('ERROR: ', errorMessage);
        });
    }
  })
}
*/
