import { Room } from "proconf-web-sdk";

//Service
import proConfService from "../../Services/ProConfService";

//Store
import { store } from "../store";

//Constants
import { SET_ROOM_NAME } from "../ReduxConstants";

export async function getMeetingList(): Promise<any[]> {
  const meetingsList = await proConfService.getMeetingList();
  return meetingsList;
}

export async function viewCallSummary(roomId: string): Promise<string> {
  let summary = await proConfService.viewCallSummary(roomId);
  return summary;
}

export async function createMeeting(
  roomName: string,
  username: string
): Promise<Room | null> {
  let roomresult = (await proConfService.startMeeting(
    roomName,
    username
  )) as Room;
  store.dispatch({ type: SET_ROOM_NAME, payload: roomName });
  return roomresult;
}

export async function joinMeeting(
  roomname: string,
  username: string
): Promise<Room | null | undefined> {
  let roomId = store.getState()?.RoomReducer?.roomId;
  

  let roomresult = await proConfService.joinMeeting(roomname, roomId, username);
  store.dispatch({ type: SET_ROOM_NAME, payload: roomname });
  return roomresult;
}

export async function getCatchup(
  roomId: string
): Promise<string | null | undefined> {
  let catchup = await proConfService.getCallCatchUp(roomId);
  return catchup;
}

export async function getCallStatus(roomId: string): Promise<boolean> {
  
  let status = await proConfService.getCallStatus(roomId);
  return status;
}
