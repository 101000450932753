import { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import image_404 from "../../Resources/Images/404image@2x.png";
import proconfLogo from "../../Resources/Images/PLogo.png"

//Styles
import "./404Page.scss";

/**
 * @description When user refer/enter wrong route which is not defined 
 * in the RouteConstants, this component will be shown.
 * @author Ruchika Phalke <ruchika.phalke@springct.com>
 * @return {FunctionComponent} jsx component to render a error page 
*/
const ErrorPage: FunctionComponent = () => {
    const navigate = useNavigate();

    /**
     * @method goBack
     * @description Go back to the previous page in the browser history
     * @author Ruchika Phalke <ruchika.phalke@springct.com>
     * @return {FunctionComponent} jsx component to render a error page 
    */
    const goBack = () => {
        navigate(-1); // This navigates back in the history stack
    }

    return (
        <div className="error-page">
            <img src={proconfLogo} alt="logo" />
            <div className="frame-parent4">
                <div className="frame-parent5">
                    <img className="frame-child" alt="404error" src={image_404} />
                    <div className="label-parent">
                        <b className="label11">{getTranslation("pagenotfound")}</b>
                        <div className="label12">
                            {getTranslation("pageNotExists")}
                        </div>
                    </div>
                </div>
                <div className="content3">
                    <button className="buttonprimary4" onClick={goBack}>
                        <b className="buttonlabel7">{getTranslation("goBack")}</b>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
