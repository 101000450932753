import { FunctionComponent } from "react";
import "./TranscriptMessageContainer.scss";

export type TranscriptMessageContainerType = {
  key: string;
  className?: string;
  transcriptName?: string;
  transcriptTime?: string;
  transcriptText?: string;
};

const TranscriptMessageContainer: FunctionComponent<
  TranscriptMessageContainerType
> = ({
  key = "",
  className = "",
  transcriptName,
  transcriptTime,
  transcriptText,
}) => {
  return (
    <div className={`transcriptmessagecontainer ${className}`} key={key}>
      <div className="transcriptnameandtime">
        <b className="transcriptname">{transcriptName}</b>
        <b className="transcriptname">{transcriptTime}</b>
      </div>
      <div className="transcriptmessage">{transcriptText}</div>
    </div>
  );
};

export default TranscriptMessageContainer;
