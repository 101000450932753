import { FunctionComponent } from "react";
import "./PrimaryButtonSmall.scss";
import PrimaryButtonInviteIcon from "../../Resources/InCallImages/PrimaryButtonInviteIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";

export type PrimaryButtonSmallType = {
  className?: string;
  onClick?: () => void
};

const PrimaryButtonSmall: FunctionComponent<PrimaryButtonSmallType> = ({
  className = "",
  onClick
}) => {
  return (
    <button className={`primarybuttonsmall ${className}`} onClick={onClick}>
      <img
        className="primarybuttonsmallicon"
        alt="primarybuttonsmallicon"
        src={PrimaryButtonInviteIcon}
      />
      <b className="primarybuttonsmalllabel">{getTranslation("invite")}</b>
    </button>
  );
};

export default PrimaryButtonSmall;
