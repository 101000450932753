import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//Translation resources
import en from "./en";

//Constants
import { LANGUAGE } from "../../Constants/AppConstants";

//The translations here for the spanish and english
const resources = {
  en: {
    translation: en,
  },

};

//function that will call when user want to change the language
export const changeLanguage = (lang: string) => {
  //use language from param/ use language from reducer
  let language = lang || LANGUAGE.ENGLISH.name
  // lang || store.getState().LanguageReducer.language || LANGUAGE.ENGLISH.name;
  i18n.changeLanguage(language);
};

//function to convert message in expected language
export const getTranslation = (key: string, interpolation: string | undefined = "") => {
  return i18n.t(key, interpolation);
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE.ENGLISH.name,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
