//APIs
import callAPI from "../../AxiosHelper/APICall";
import { URLS } from "../../AxiosHelper/ApiUrls";

//Constants
import { API_STATUS, METHOD } from "../../Constants/AppConstants";
import {
  LOGOUT,
  SET_AUTH_TOKEN,
  SET_GUEST_USER_NAME,
  SET_PROFILE_NAME,
  SET_ROOM_NAME,
} from "../ReduxConstants";

//Store
import { store } from "../store";

//Actions
import { setDataIntoReduxStore } from "./StoreAction";

/**
 *
 * @param username username entered on the login page
 * @param password password entered on the login page
 * @returns api response to get the token based on the credentials
 */
export async function callLoginAPI(username: string, password: string) {
  try {
    // Call the API function to get the token
    const response = await callAPI({
      method: METHOD.HTTP.POST,
      url: URLS.LOGIN,
      requestBody: { username: username, password: password },
    });

    if (response?.status === API_STATUS.SUCCESS) {
      setDataIntoReduxStore(SET_AUTH_TOKEN, response?.response?.data?.token);
      setDataIntoReduxStore(SET_PROFILE_NAME, response?.response?.data?.name);
    }

    return response;
  } catch (error) {}
}

export async function callJoinAsGuestnAPI(
  userName: string,
  roomId: string,
  roomName: string
) {
  const url = URLS.JOIN_AS_GUEST + `?room_id=${roomId}`;
  try {
    // Call the API function to get the guest token
    const response = await callAPI({
      method: METHOD.HTTP.GET,
      url: url,
    });

    if (response?.status === API_STATUS.SUCCESS) {
      setDataIntoReduxStore(SET_AUTH_TOKEN, response?.response?.data);
      setDataIntoReduxStore(SET_GUEST_USER_NAME, userName);
      setDataIntoReduxStore(SET_ROOM_NAME, roomName);
    }

    return response;
  } catch (error) {}
}

/**
 *
 * @returns logot action to clear the redux state
 */
export const logoutAction = () => {
  store.dispatch({ type: LOGOUT });
};
