import { FunctionComponent } from "react";
import cross from "../../Resources/Images/icadd2@2x.png"; //icadd25@2x.png";
import "./Chip.scss";

export type ChipInviteType = {
  className?: string;
  emailtext?: string;
  index?: number;
  removeEmail?: (index: number) => void;
};

const ChipInvite: FunctionComponent<ChipInviteType> = ({
  className = "",
  emailtext = "david.jonathan@gmail.com",
  index = -1,
  removeEmail = () => {},
}) => {
  return (
    <div className={`chipinvite ${className}`}>
      <div className="davidjonathangmailcom">{emailtext}</div>
      <button className="closebutton2" onClick={() => removeEmail(index)}>
        <img className="icadd2-icon18" alt="" src={cross} />
      </button>
    </div>
  );
};

export default ChipInvite;
