import { ToolbarState } from "../StateTypes/StateTypes";
import { ToolbarActionTypes } from "../ActionTypes/ToolbarActionTypes";
import {
  ON_MIC_MUTE_UNMUTE,
  ON_VIDEO_MUTE_UNMUTE,
  ON_RECORDING_TOGGLE,
  ON_SCREEN_SHARE_TOGGLE,
  ON_TRANSCRIPT_TOGGLE,
  ON_TRANSCRIPT_ENABLED,
  ON_PARTICIPANT_PANEL_TOGGLE,
  ON_CHANGE_LAYOUT,
  ON_SPEAKER_MUTE_UNMUTE,
  ON_MORE_ACTIONS,
  ON_MORE_ACTIONS_MOBILE,
  ON_END_CALL,
  RESET_TOOLBAR_STATE,
  SET_SELECTED_SPEAKER,
  SET_SELECTED_MIC,
  SET_SELECTED_CAMERA,
  ON_NOISE_CANCELLATION_TOGGLE,
  SET_TRANSCRIPTION_STATE,
} from "../ReduxConstants";

// Define the initial state for the ProConf reducer
const initialState: ToolbarState = {
  audioMute: false,
  videoMute: false,
  recordingStarted: false,
  screenShareStarted: false,
  transcriptOn: false, //this is for panel toggle
  transcriptionEnabled: false,
  participantPanelOpen: false,
  layout: "",
  speakerMute: false,
  moreActionClicked: false,
  moreActionMobileClicked: false,
  isEndButtonClicked: false,
  selectedSpeaker: {}, // from create meeting screen
  selectedMic: {}, // from create meeting screen
  selectedCamera: {}, // from create meeting screen
  isNoiseCancellationEnabled: false,
};

// Reducer function to handle state changes based on actions
const ToolbarReducer = (
  state = initialState,
  action: ToolbarActionTypes
): ToolbarState => {

  switch (action.type) {
    case ON_MIC_MUTE_UNMUTE:
      return { ...state, audioMute: !state.audioMute };

    case ON_VIDEO_MUTE_UNMUTE:
      return { ...state, videoMute: !state.videoMute };

    case ON_RECORDING_TOGGLE:
      return { ...state, recordingStarted: !state.recordingStarted };

    case ON_SCREEN_SHARE_TOGGLE:
      return { ...state, screenShareStarted: !state.screenShareStarted };

    case ON_TRANSCRIPT_TOGGLE:
      return { ...state, transcriptOn: !state.transcriptOn, participantPanelOpen: false };

    case ON_TRANSCRIPT_ENABLED:
      return { ...state, transcriptionEnabled: !state.transcriptionEnabled };

    case ON_PARTICIPANT_PANEL_TOGGLE:
      return { ...state, participantPanelOpen: !state.participantPanelOpen, transcriptOn: false };

    case ON_CHANGE_LAYOUT:
      return { ...state, layout: action.payload };

    case ON_SPEAKER_MUTE_UNMUTE:
      return { ...state, speakerMute: !state.speakerMute };
    
    case ON_NOISE_CANCELLATION_TOGGLE:
      return { ...state, isNoiseCancellationEnabled: !state.isNoiseCancellationEnabled };

    case ON_MORE_ACTIONS:
      return { ...state, moreActionClicked: !state.moreActionClicked };

    case ON_MORE_ACTIONS_MOBILE:
      return { ...state, moreActionMobileClicked: !state.moreActionMobileClicked };

    case ON_END_CALL:
      return { ...state, isEndButtonClicked: !state.isEndButtonClicked };

    //TODO: Create separate reducer for select actions
    case SET_SELECTED_SPEAKER:
      return { ...state, selectedSpeaker: action.payload };

    case SET_SELECTED_MIC:
      return { ...state, selectedMic: action.payload };

    case SET_SELECTED_CAMERA:
      return { ...state, selectedCamera: action.payload };

    case SET_TRANSCRIPTION_STATE:
      return { ...state, transcriptionEnabled: action.payload };

    case RESET_TOOLBAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default ToolbarReducer;
