import { FunctionComponent, useMemo, type CSSProperties } from "react";
import "./ToolBarButton.scss";

export type ToolBarButtonType = {
  className?: string;
  toolbarButtonOffIcon?: string;
  toolbarButtonLabel?: string;
  toolbarButtonOnIcon?: string;
  isOn?: boolean;
  toolBarButtonClick?: () => void;

  /** Style props */
  propBorder?: CSSProperties["border"];
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propColor?: CSSProperties["color"];
};

const ToolBarButton: FunctionComponent<ToolBarButtonType> = ({
  className = "",
  propBorder,
  propBackgroundColor,
  toolbarButtonOffIcon,
  toolbarButtonLabel,
  toolBarButtonClick,
  propColor,
  toolbarButtonOnIcon,
  isOn,
}) => {
  const toolBarButtonStyle: CSSProperties = useMemo(() => {
    return {
      border: propBorder,
      backgroundColor: propBackgroundColor,
    };
  }, [propBorder, propBackgroundColor]);

  const toolIconLabelStyle: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <button
      className={`toolbarbutton ${className}`}
      style={toolBarButtonStyle}
      onClick={toolBarButtonClick}
    >
      {!isOn && (
        <img
          className="callicon2"
          alt="RecordOffMediaControlIconLarge"
          src={toolbarButtonOffIcon}
        />
      )}
      {isOn && (
        <img
          className="callicon3"
          alt="RecordOnMediaControlIconLarge"
          src={toolbarButtonOnIcon}
        />
      )}
      <div className="tooliconlabel" style={toolIconLabelStyle}>
        {toolbarButtonLabel}
      </div>
    </button>
  );
};

export default ToolBarButton;
