import { FunctionComponent } from "react";
import "./SecondaryButtonSmall.scss";
import SecondaryButtonCopyIcon from "../../Resources/InCallImages/SecondaryButtonCopyIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";

export type SecondaryButtonSmallType = {
  className?: string;
  buttonLabel?: string;
  onClick?: (e?: any) => void
};

const SecondaryButtonSmall: FunctionComponent<SecondaryButtonSmallType> = ({
  className = "",
  buttonLabel,
  onClick
}) => {
  return (
    <button className={`secondarybuttonsmall ${className}`} onClick={onClick}>
      <img
        className="secondarybuttonsmallicon"
        alt="SecondaryButtonCopyIcon"
        src={SecondaryButtonCopyIcon}
      />
      <b className="secondarybuttonsmalllabel">{buttonLabel || getTranslation("copyLink")}</b>
    </button>
  );
};

export default SecondaryButtonSmall;
