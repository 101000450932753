//Store
import { store } from '../store';

//Constants
import { ERROR_NOTIFICATION, WARNING_NOTIFICATION, SUCCESS_NOTIFICATION } from '../ReduxConstants';

export function setErrorNotification(message: string | undefined) {
    store.dispatch({
        type: ERROR_NOTIFICATION,
        payload: message
    })
}

export function setWarningNotification(message: string) {
    store.dispatch({
        type: WARNING_NOTIFICATION,
        payload: message
    })
}

export function setSuccessNotification(message: string) {
    store.dispatch({
        type: SUCCESS_NOTIFICATION,
        payload: message
    })
}