import { FunctionComponent, useEffect, useState, useRef, useMemo } from "react";
import "./CallScreen.scss";

import { useMobileOrientation } from "react-device-detect";
import CallHeader from "../../Common Components/CallHeader/CallHeader";
import ActionToolBar from "../../Common Components/ActionToolBar/ActionToolBar";
import TranscriptPanel from "../../Common Components/TransciptPanel/TranscriptPanel";
// import ChatPanel from "../../Common Components/New-ChatPanel/ChatPanel";
import ParticipantsPanel from "../../Common Components/ParticipantsPanel/ParticipantsPanel";
import ParticipantTile from "../../Common Components/ParticipantTile/ParticipantTile";
import {
  getSmallTileSize,
  getTileSize,
  isDesktopDevice,
  isMobileDevice,
  isTabletDevice,
} from "../../Utility/Utils";
import { useSelector } from "react-redux";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import InviteViaEmailPopUp from "../InviteParticipant/InviteViaEmailPopup";
import { Track } from "proconf-web-sdk";

const CallScreen: FunctionComponent = () => {
  const [openInvitePopup, setOpenInvitePopup] = useState(false);
  const [showChatPanel, setShowChatPanel] = useState(false);
  const [showTranscriptPanel, setShowTranscriptPanel] = useState(false);
  const [showParticipantsPanel, setShowParticipantsPanel] = useState(false);
  const [activeRecording, setActiveRecording] = useState(false);
  const [callTileCount, setCallTileCount] = useState(0);
  const [activePanel, setActivePanel] = useState("");
  const [bigTileSize, setBigTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
  });
  const [smallTileSize, setSmallTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
    noOfUsersToFit: 0,
  });

  const sidePanelRef = useRef<HTMLDivElement>(null);

  const { isLandscape, isPortrait } = useMobileOrientation();

  const participants = useSelector(
    (state: any) => state.ParticipantReducer?.participants
  );
  const isScreenShared = useSelector(
    (state: any) => state.ProConfReducer?.isRemoteSSOn
  );
  const screensharingParticicpants = useSelector((state: any) => {
    return state.ParticipantReducer?.screenSharingParticipants;
  });

  const {
    audioMute,
    videoMute,
    screenShareStarted,
    isNoiseCancellationEnabled,
  } = useSelector((state: any) => state.ToolbarReducer);

  const toggleChatPanel = () => {
    !showChatPanel ? setActivePanel("CHAT") : setActivePanel("");
    setShowChatPanel(!showChatPanel);
  };

  const toggleTranscriptPanel = () => {
    !showTranscriptPanel ? setActivePanel("TRANSCRIPT") : setActivePanel("");
    setShowTranscriptPanel(!showTranscriptPanel);
  };

  const toggleParticipantsPanel = () => {
    !showParticipantsPanel
      ? setActivePanel("PARTICIPANTS")
      : setActivePanel("");
    setShowParticipantsPanel(!showParticipantsPanel);
  };

  const onClickRecording = () => {
    setActiveRecording(!activeRecording);
  };

  const handleOrientationChange = () => {
    setShowTranscriptPanel(!!showTranscriptPanel);
    setShowParticipantsPanel(!!showParticipantsPanel);
    setShowChatPanel(!!showChatPanel);
  };

  useEffect(() => {
    setTileLayout();
    if (!isDesktopDevice) {
      window.addEventListener("resize", setTileLayout);
      window.addEventListener("orientationchange", handleOrientationChange);
    }
    return () => {
      window.removeEventListener("resize", setTileLayout);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTileLayout();
    if (!isDesktopDevice) {
      window.addEventListener("resize", setTileLayout);
      window.addEventListener("orientationchange", handleOrientationChange);
    }
    return () => {
      window.removeEventListener("resize", setTileLayout);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [participants, callTileCount, isScreenShared]);

  useEffect(() => {
    setTimeout(() => {
      participants?.map((singleParticipant: any) => {
        let tracks = Array.from(
          singleParticipant.videoTracks.values()
        ) as Track[];
        if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
          tracks[0].attach(
            document.getElementById(
              "video-" + singleParticipant?.sid
            ) as HTMLVideoElement
          );
        }
        return null;
      });
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenShared]);

  useMemo(() => {
    if (isMobileDevice || isTabletDevice) {
      setTimeout(() => {
        participants?.map((singleParticipant: any) => {
          let tracks = Array.from(
            singleParticipant.videoTracks.values()
          ) as Track[];
          if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
            tracks[0].attach(
              document.getElementById(
                "video-" + singleParticipant?.sid
              ) as HTMLVideoElement
            );
          }
          return null;
        });
      }, 1000);
    }
  }, [showChatPanel, showParticipantsPanel, showTranscriptPanel]);

  const setTileLayout = () => {
    let parentElement = document.getElementById(
      isDesktopDevice || isTabletDevice ? "callbody" : "calltiles"
    );
    if (parentElement) {
      const size: DOMRect = parentElement.getBoundingClientRect();
      if (isDesktopDevice || isTabletDevice) {
        size.width =
          size?.width -
          (showChatPanel || showTranscriptPanel
            ? document
                .getElementById("leftnavcontainer")
                ?.getBoundingClientRect().width ?? 0
            : 0) -
          (showParticipantsPanel
            ? document
                .getElementById("participantspanel")
                ?.getBoundingClientRect().width ?? 0
            : 0) -
          (document.getElementById("sidetilespanel")?.getBoundingClientRect()
            .width || 0);
      }

      setBigTileSize(getTileSize(size, callTileCount, participants));
      if (participants.length - callTileCount > 0) {
        setSmallTileSize(getSmallTileSize(sidePanelRef.current, 10, 10));
      }
    }
  };

  useEffect(() => {
    setTileLayout();
    if (isScreenShared) {
      setCallTileCount(1);
    } else if ((isMobileDevice || isTabletDevice) && isPortrait) {
      if (
        showChatPanel ||
        showTranscriptPanel ||
        showParticipantsPanel ||
        isScreenShared
      ) {
        setCallTileCount(1);
      } else if (isTabletDevice) {
        setCallTileCount(4);
      } else if (isMobileDevice) {
        setCallTileCount(3);
      }
    } else {
      setCallTileCount(4);
    }
    if ((isMobileDevice || isTabletDevice) && !isDesktopDevice) {
      if (showChatPanel && activePanel === "CHAT") {
        setShowTranscriptPanel(false);
        setShowParticipantsPanel(false);
      } else if (showTranscriptPanel && activePanel === "TRANSCRIPT") {
        setShowParticipantsPanel(false);
        setShowChatPanel(false);
      } else if (showParticipantsPanel && activePanel === "PARTICIPANTS") {
        setShowTranscriptPanel(false);
        setShowChatPanel(false);
      }
    }
  }, [
    showChatPanel,
    showTranscriptPanel,
    showParticipantsPanel,
    isScreenShared,
  ]);

  let heightOfCallscreen = "100vh";
  if (isMobileDevice) {
    heightOfCallscreen = "calc(100vh - 60px)";
  } else if (isTabletDevice) {
    heightOfCallscreen = "calc(100vh - 100px)";
  }

  return (
    <div
      className={
        isMobileDevice &&
        isLandscape &&
        (showChatPanel || showTranscriptPanel || showParticipantsPanel)
          ? "callscreen scrollableView"
          : "callscreen"
      }
      style={{ height: heightOfCallscreen }}
    >
      <CallHeader showRecordingIcon={activeRecording} />
      <div className="callbody" id="callbody">
        {(showChatPanel || showTranscriptPanel) && (
          <div className="leftnavcontainer" id="leftnavcontainer">
            {showTranscriptPanel && (
              <TranscriptPanel toggleTranscriptPanel={toggleTranscriptPanel} />
            )}
            {/* Hide chat panel */}
            {/* {showChatPanel && <ChatPanel toggleChatPanel={toggleChatPanel} />} */}
          </div>
        )}
        <div className="callbodycontainer">
          <div className="callmaincontainer">
            <div className="calltiles" id="calltiles">
              {isScreenShared ? (
                <ParticipantTile
                  participant={screensharingParticicpants[0]}
                  screenShared={true}
                />
              ) : (
                participants
                  .slice(0, Math.min(participants.length, callTileCount))
                  .map((participant: any) => (
                    <ParticipantTile
                      key={participant?.sid}
                      tileSize={bigTileSize}
                      participant={participant}
                      allParticipants={participants}
                    />
                  ))
              )}
            </div>
          </div>
          {participants.slice(callTileCount).length !== 0 && (
            <div
              className="sidetilespanel"
              id="sidetilespanel"
              ref={sidePanelRef}
            >
              {participants
                .slice(isScreenShared ? null : callTileCount)
                .slice(
                  0,
                  Math.min(
                    smallTileSize.noOfUsersToFit,
                    participants.slice(isScreenShared ? null : callTileCount)
                      .length
                  )
                )
                .map((participant: any) => (
                  <ParticipantTile
                    key={participant?.sid}
                    tileSize={smallTileSize}
                    participant={participant}
                    smallTile={true}
                  />
                ))}
            </div>
          )}
        </div>
        {showParticipantsPanel && (
          <ParticipantsPanel
            toggleParticipantsPanel={toggleParticipantsPanel}
            setInvitePopup={setOpenInvitePopup}
          />
        )}

        {openInvitePopup && (
          <PortalPopup placement="Centered">
            <InviteViaEmailPopUp setInvitePopup={setOpenInvitePopup} />
          </PortalPopup>
        )}
      </div>
      <ActionToolBar
        activeRecording={activeRecording}
        showChatPanel={showChatPanel}
        showParticipantsPanel={showParticipantsPanel}
        showTranscriptPanel={showTranscriptPanel}
        toggleChatPanel={toggleChatPanel}
        toggleTranscriptPanel={toggleTranscriptPanel}
        toggleParticipantsPanel={toggleParticipantsPanel}
        toggleRecording={onClickRecording}
        isScreenShared={isScreenShared}
        audioMute={audioMute}
        videoMute={videoMute}
        screenShareStarted={screenShareStarted}
        participants={participants}
        isNoiseCancellationEnabled={isNoiseCancellationEnabled}
      />
    </div>
  );
};

export default CallScreen;
