import { Dropdown, Menu, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

//Styles
import "./CustomDropDown.scss"

// @ts-ignore
export default function CustomDropDown({ options, onSelectChange, selectedOption }) {
    return (
        <Dropdown
            className="inputfield2"
            overlay={
                <Menu>
                    {(
                        options as any
                    ).map((option: any, index: number) => (
                        <Menu.Item key={index}>
                            <a onClick={(e) => onSelectChange(e, option)}>
                                {option.label || ""}
                            </a>
                        </Menu.Item>
                    ))}
                </Menu>
            }
            arrow={undefined}
            trigger={["click"]}
        >
            <Button onClick={(e) => e.preventDefault()}>
                <span className="label-wrapper">
                    <span className="selected-option"> {selectedOption?.label}</span>
                    <DownOutlined />
                </span>
            </Button>
        </Dropdown>
    )
}