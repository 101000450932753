import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import "./CallHeader.scss";
// import TimeandRecordIcon from "../TimeandRecordIcon/TimeandRecordIcon";

export type CallHeaderType = {
  className?: string;
  showRecordingIcon?: boolean;
};

const CallHeader: FunctionComponent<CallHeaderType> = ({
  className = "",
  showRecordingIcon,
}) => {
  const meetingName = useSelector((state: any) => state?.RoomReducer?.roomName);

  return (
    <div className={`callheader ${className}`}>
      <div className="meetingnameandtime">
        <b className="incallmeetingname">{meetingName}</b>
        {/* Hide Time and Recording */}
        {/* <TimeandRecordIcon
          meetingTime="02:01:45"
          showRecordingIcon={showRecordingIcon}
        /> */}
      </div>
    </div>
  );
};

export default CallHeader;
