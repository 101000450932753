import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

//Action
import { getAdminBackendDetails, getWebSDKDetails } from "../../Redux/Actions/AppDetailsAction";

//Assets
import proconfLogo from "../../Resources/Images/PLogo.png"

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Styles
import "./About.scss";

/**
 * @description This component will show the details/versions
 *  of the components involved in this React app.
 * @author Ruchika Phalke <ruchika.phalke@springct.com>
 * @return {FunctionComponent} jsx component to render a About page 
*/
const About: FunctionComponent = () => {
  const navigate = useNavigate();

  const [adminBackendVersion, setadminBackendVersion] = useState("");
  const [webSDKvesrion, setWebSDKvesrion] = useState("");

  async function getApplicationsDetails() {
    let adminVersion = await getAdminBackendDetails();
    setadminBackendVersion(adminVersion?.adminBackend)

    let sdkVersion = await getWebSDKDetails();
    // @ts-ignore
    setWebSDKvesrion(sdkVersion)
  }

  useEffect(() => {
    getApplicationsDetails()
  }, [])


  /**
   * @method goBack
   * @description Go back to the previous page in the browser history
   * @author Ruchika Phalke <ruchika.phalke@springct.com>
  */
  const goBack = () => {
    navigate(-1); // This navigates back in the history stack
  }

  return (
    <div className="error-page">
      <img src={proconfLogo} alt="logo" />
      <div className="frame-parent4">
        <div className="frame-parent5">
          <div className="label-parent">
            <b className="label11">{getTranslation("versionInfo")}</b>
            <div className="label13">
              <div className="label13">{"React App" + " " + getTranslation("version") + " - "} {process.env.VERSION}</div>
              <div className="label13">{"Web SDK" + " " + getTranslation("version") + " - "}{webSDKvesrion}</div>
              <div className="label13">{"Admin Backend" + " " + getTranslation("version") + " - "} {adminBackendVersion}</div>
            </div>
          </div>
        </div>
        <div className="content3">
          <button className="buttonprimary4" onClick={goBack}>
            <b className="buttonlabel7">{getTranslation("goBack")}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;

