import {
  ProConfManager,
  Connection,
  Room,
  PROCONF_EVENTS,
  Participant,
  Track,
} from "proconf-web-sdk";
import { store } from "../Redux/store";
import {
  getFilteredConfiguration,
} from "../Utility/Utils";
import {
  ON_SCREEN_SHARE_TOGGLE,
  SET_AVAILABLE_CONFIGURATIONS,
  CLEAR_INCALL_REDUCER,
  CALL_IN_PROGRESS,
  CLEAR_PARTICIPANTS,
  CLEAR_ROOM,
  RESET_TOOLBAR_STATE,
  SET_REMOTE_SS,
  SET_ROOM_ENDED,
  CLEAR_NOTIFICATIONS,
} from "../Redux/ReduxConstants";
import { applyAudioOutputDeviceSelection } from "../Utility/DeviceUtils";
import { setTranscriptions } from "../Redux/Actions/InCallAction";
import {
  setErrorNotification,
  setWarningNotification,
} from "../Redux/Actions/NotificationAction";
import { getTranslation } from "../Resources/Localization/i18n";
import { onScreenSharing } from "../Redux/Actions/ToolbarActions";
import { CONFIGURATIONS } from "../Constants/AppConstants";

class ProConfService {
  static proConfManager: ProConfManager | null = null;
  static connection: Connection | null = null;

  public room: Room | null | undefined = null;
  public localParticipant: Participant | undefined | null = undefined;
  public tracks: any;
  private isProconfInitialized = false;
  public screenTrack: Track | undefined | null = undefined;
  //public screenTracks: Track[] | undefined | null = undefined;
  public isCallInProgress = false;

  public localVideoEl: HTMLVideoElement | null =
    (document.getElementById("local-video") as HTMLVideoElement) || null;

  private screenShareDialogOpen = false;

  // private dispatch = useTypedDispatch();
  constructor() {
    // TODO: if everything works fine , remove this init call from here
    // this.initProConf();
    this.localVideoEl = document.getElementById(
      "local-video"
    ) as HTMLVideoElement;
  }

  public getConnection() {
    return ProConfService.connection;
  }

  // Initialize ProConfManager
  public async initProConf() {
    try {
      ProConfService.proConfManager = ProConfManager.getInstance();
      store.dispatch({ type: "IS_PROCONF_INIT", payload: false });
      let token = store.getState()?.LoginReducer?.token;
      if (token)
        await ProConfService.proConfManager
          .init({
            appServerUrl: process.env.REACT_APP_SERVER_DOMAIN,
            appBearerToken: token,
            // noiseCancellationWasmUrl: 'http://127.0.0.1:5500/rnnoise.wasm' // Passing locally hosted rnn wasm
          })
          .then(() => {
            store.dispatch({
              type: "INIT_PROCONF_SUCCESS",
              payload: ProConfService.proConfManager,
            });
            store.dispatch({ type: "IS_PROCONF_INIT", payload: true });

            console.log("Initialization successful!");
            this.isProconfInitialized = true;
          });
      this.getCallConfig();
    } catch (error) {
      console.error("Initialization failed:", error);
      store.dispatch({ type: "PROCONF_ERROR", payload: error });
      throw error;
    }
  }

  //get sdk version
  public async getSDKVersion() {
    const version = ProConfService?.proConfManager?.getVersion();
    console.log("version: ", version);
    return version;
  }

  // Get call configurations
  public getCallConfig() {
    const Configs = ProConfService?.proConfManager?.getCallConfig();
    const filteredConfigs = getFilteredConfiguration(Configs);
    console.log("getCallConfig configurations: ", Configs, filteredConfigs);
    store.dispatch({
      type: SET_AVAILABLE_CONFIGURATIONS,
      payload: filteredConfigs,
    });
    console.log("getCallConfig Configs: ", Configs);
    return Configs;
  }

  // Enable/Disable Transcription
  public enableTranscription(isEnabled: boolean) {
    if (this.room) {
      console.log("this.room: ", this.room);
      this.room?.enableTranscription(isEnabled);
    }
  }

  // Create local tracks from selected media devices
  public async createTracks(options: any) {
    if (ProConfService.proConfManager && this.isProconfInitialized) {
      const tracks = await ProConfService.proConfManager?.createLocalTracks({
        devices:
          CONFIGURATIONS.VIDEO_CALL in
          store.getState()?.LoginReducer?.configurations
            ? ["audio", "video"]
            : ["audio"],
        cameraDeviceId: options?.cameraDeviceId,
        micDeviceId: options?.micDeviceId || "default",
      });
      this.tracks = tracks;
      return this.tracks;
    }
  }

  // Start meeting
  public async startMeeting(
    roomName: string,
    username: string,
    roomId?: string
  ) {
    const micId = store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
    const cameraId = store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;

    if (ProConfService.proConfManager && this.isProconfInitialized) {
      try {
        const roomOptions = {
          roomName: roomName,
          roomId: roomId,
          participantName: username.trim(),
        };

        console.log("roomOptions: ", roomOptions);

        if (!this.tracks) {
          if (!micId || !cameraId) {
            await this.createDefaultTracks();
          } else {
            await this.createTracksAndJoin(roomOptions, true);
          }
        } else {
          this.room = await ProConfService.proConfManager?.startMeeting(
            roomOptions,
            this.tracks
          );
        }
        if (this.room) {
          store.dispatch({ type: "SET_ROOM_ID", payload: this.room?.id });

          store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: this.room });
          store.dispatch({
            type: "SET_TRANSCRIPTION_STATE",
            payload: this.room.isTranscribing,
          });

          this.isCallInProgress = true;
          this.bindRoomEvents(this.room);
          this.localParticipant = this.room.localParticipant as Participant;
          this.storeLocalParticipant();
          this.attachLocalTracks(this.room as Room);
          // Store already existing remote participants
          this.handleRemoteParticipants(this.room as Room);
          console.log(
            "ProCONF service:::startMeeting: Connected successfully! roomId",
            this.room,
            this.room?.id,
            store?.getState()?.RoomReducer.roomId
          );
          return this.room;
        }
      } catch (error) {
        console.error(
          "ProCONF service:::startMeeting: Connection failed:",
          error
        );
        if (error?.toString()?.includes("max_users")) {
          setErrorNotification(getTranslation("maxParticipantLimit"));
          window?.history?.back();
          throw error;
        } else {
          store.dispatch({ type: "PROCONF_ERROR", payload: error });
          //@ts-ignore
          setErrorNotification(error?.message);
          throw error;
        }
      } finally {
        setTimeout(() => {
          store.dispatch({ type: CLEAR_NOTIFICATIONS });
        }, 2000);
      }
    } else {
      throw new Error(
        "ProCONF service:::ProConfManager is not initialized yet."
      );
    }
  }

  // Join meeting
  public async joinMeeting(roomname: string, roomId: string, username: string) {
    const micId = store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
    const cameraId = store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;

    if (ProConfService.proConfManager && this.isProconfInitialized) {
      try {
        const roomOptions = {
          roomName: roomname?.trim(),
          roomId: roomId?.trim(),
          participantName: username?.trim(),
        };
        console.log("roomOptions: roomId", roomOptions);

        if (!this.tracks) {
          if (!micId || !cameraId) {
            await this.createDefaultTracks();
          } else {
            // Pass 2nd param as false in case of joinmeeting
            await this.createTracksAndJoin(roomOptions, false);
          }
        } else {
          this.room = await ProConfService.proConfManager?.joinMeeting(
            roomOptions,
            this.tracks
          );
        }
        if (this.room) {
          store.dispatch({ type: "JOIN_ROOM_SUCCESS", payload: this.room });
          console.log(
            "ProCONF service:::joinMeeting: Connected successfully!",
            this.room
          );
          this.bindRoomEvents(this.room);
          this.localParticipant = this.room.localParticipant as Participant;
          this.tracks = Array.from(this.localParticipant?.tracks?.values());
          this.storeLocalParticipant();
          // Store already existing remote participants
          this.handleRemoteParticipants(this.room as Room);
          this.attachLocalTracks(this.room as Room);
        } else {
          console.log("Room not created");
        }
        return this.room;
      } catch (error) {
        console.error("joinMeeting: Connection failed:", error);
        if (error?.toString()?.includes("max_users")) {
          setErrorNotification(getTranslation("maxParticipantLimit"));
          window?.history?.back();
          throw error;
        } else {
          store.dispatch({ type: "PROCONF_ERROR", payload: error });
          //@ts-ignore
          setErrorNotification(error?.message);
          throw error;
        }
      } finally {
        setTimeout(() => {
          store.dispatch({ type: CLEAR_NOTIFICATIONS });
        }, 2000);
      }
    } else {
      throw new Error("ProConfManager is not initialized yet.");
    }
  }

  // Bind room events
  private bindRoomEvents(room: Room) {
    console.log("ProCONF service:::bindRoomEvents:");
    this.handleTrackEvents(room);
    room.on(
      PROCONF_EVENTS.PARTICIPANT_CONNECTED,
      (participant: Participant) => {
        console.log("ProCONF service:::Participant connected:", participant);
        this.bindParticipantEvents(participant);
        store.dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            ...participant,
            tracks: Array.from(participant?.tracks?.values()),
            audioTracks: Array.from(participant?.audioTracks?.values()),
            videoTracks: Array.from(participant?.videoTracks?.values()),
            isLocal: false,
          },
        });
      }
    );
    room.on(PROCONF_EVENTS.ROOM_CREATED_TIMESTAMP, (timestamp) => {
      console.log("ROOM_CREATED_TIMESTAMP event: ", timestamp);

      store.dispatch({ type: "SET_MEETING_TIME", payload: timestamp });
    });
    room.on(
      PROCONF_EVENTS.PARTICIPANT_DISCONNECTED,
      (participant: Participant) => {
        console.log("ProCONF service:::Participant disconnected:", participant);
        store.dispatch({
          type: "REMOVE_PARTICIPANT",
          payload: participant?.sid,
        });
      }
    );
    room.on(
      PROCONF_EVENTS.DISCONNECTED,
      async (room: Room, error: any, reason: any) => {
        console.log("ProCONF service:::Disconnected:", reason, error);
        // Clear session
        if (reason !== "Room left") {
          // Warning notification only when meeting was auto ended/ended by moderator
          setWarningNotification(getTranslation("meetingEndedMsg"));
        }
        this.isCallInProgress = false;
        // Wait for all tracks to stop and then redirect (fix141)
        await this.cleanUp();
        this.redirect();

        // Fix231: If screen selection dialog before selecting and sharing screen is open, then we can reload page to close it
        if (this.screenShareDialogOpen) {
          window.location.reload();
        }
      }
    );
    room.on(PROCONF_EVENTS.DOMINANT_SPEAKER_CHANGED, (participant: any) => {
      console.log("ProCONF service:::DOMINANT_SPEAKER_CHANGED", participant);
    });

    room.on(PROCONF_EVENTS.ENDPOINT_MESSAGE_RECEIVED, (data: any) => {
      console.log("ROOM_EVENTS data: ", data);
      setTranscriptions(data);
    });

    room.on(PROCONF_EVENTS.TRACK_UNPUBLISHED, (track: Track) => {
      console.log("ProCONF service:::TRACK_UNPUBLISHED", track);
      // If a screen sharing participant leaves meeting
      if (track?.name.includes("screen")) {
        store.dispatch({
          type: "SET_REMOTE_SS",
          payload: false,
        });
      }
    });
    room.on(PROCONF_EVENTS.ROOM_ERROR, (event: any) => {
      console.log("ProCONF service:::ROOM_ERROR max participants:", event);
      // Clear session
    });
    room.on(PROCONF_EVENTS.TRANSCRIPTION_STATUS_CHANGED, (event: any) => {
      console.log("ProCONF service:::TRANSCRIPTION_STATUS_CHANGED", event);
      // Set transcription toggle as on/off here
      store.dispatch({
        type: "SET_TRANSCRIPTION_STATE",
        payload: event,
      });
    });
    room.on(PROCONF_EVENTS.NOISE_CANCELLATION_ERROR, (event: any) => {
      console.log("ProCONF service::: NOISE_CANCELLATION_ERROR:", event);
      // TODO: Handle error, states etc.
    });
  }

  // Handle local track events
  private handleTrackEvents(room: Room) {
    let vidEl = document.getElementById(
      "video-" + room?.localParticipant?.sid
    ) as HTMLVideoElement;
    console.log("ProCONF service:::handleTrackEvents", room);
    room.localParticipant?.on(
      PROCONF_EVENTS.TRACK_PUBLISHED,
      (localTrack: Track) => {
        console.log("ProCONF service:::TRACK_PUBLISHED", localTrack);
        if (localTrack.kind === "video") {
          // Handle video track
          localTrack.on(PROCONF_EVENTS.ENABLED, (d) => {
            console.log("ProCONF service:::ENABLED", d);
            if (
              localTrack.kind === "video" &&
              localTrack?.getVideoType() === "camera"
            ) {
              localTrack.attach(vidEl);
            }
          });
          localTrack.on(PROCONF_EVENTS.DISABLED, (d) => {
            console.log("ProCONF service:::DISABLED", d);
            if (localTrack.kind === "video") {
              localTrack.detach(vidEl);
            }
          });
        }
        if (localTrack.track.kind === "audio") {
          // Handle audio track
        }
      }
    );
  }

  // Store already existing remote participants
  private handleRemoteParticipants(room: Room) {
    const remoteParticipants = Array.from(
      room?.participants?.values()
    ) as Participant[];
    remoteParticipants?.forEach((participant: Participant) => {
      console.log("Existing Remote participants: ", participant);
      this.bindParticipantEvents(participant);
      const isParticipantExist = store
        .getState()
        ?.ParticipantReducer.participants.some(
          (p: Participant) => p.sid === participant.sid
        );
      if (
        !isParticipantExist &&
        participant.sid !== room?.localParticipant?.sid
      ) {
        store.dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            ...participant,
            tracks: Array.from(participant?.tracks?.values()),
            audioTracks: Array.from(participant?.audioTracks?.values()),
            videoTracks: Array.from(participant?.videoTracks?.values()),
            isLocal: false,
          },
        });
      }
    });
  }

  // Bind remote participant events
  private bindParticipantEvents(participant: Participant) {
    console.log("ProCONFservice:::bindParticipantEvents", participant);
    participant.on(PROCONF_EVENTS.TRACK_SUBSCRIBED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_SUBSCRIBED", participant, track);
      this.bindTrackEvents(participant, track);

      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...participant,
          tracks: Array.from(participant?.tracks?.values()),
          audioTracks: Array.from(participant?.audioTracks?.values()),
          videoTracks: Array.from(participant?.videoTracks?.values()),
          isLocal: false,
        },
      });
      console.log(
        "Setting sinkid of newly joined remote Participant: ",
        participant.sid
      );
      if (
        document.getElementById("video-" + participant.sid) &&
        track?.getVideoType() !== "desktop"
      ) {
        this.setSinkId(document.getElementById("video-" + participant.sid));
      }

      // If screen track is received
      if (track?.kind === "video") {
        console.log("Before 2 secs", track?.getVideoType(), track);
        // Added delay as object was not getting updated for screentrack (fix #162)
        setTimeout(() => {
          console.log("After 2 secs", track?.getVideoType(), track);
          if (track?.getVideoType() === "desktop" && track?.isEnabled) {
            store.dispatch({
              type: "SET_SCREEN_SHARING_PARTICIPANT",
              payload: {
                ...participant,
                tracks: Array.from(participant?.tracks?.values()),
                audioTracks: Array.from(participant?.audioTracks?.values()),
                videoTracks: Array.from(participant?.videoTracks?.values()),
                isLocal: false,
              },
            });
            store.dispatch({
              type: "SET_REMOTE_SS",
              payload: true,
            });
            setTimeout(() => {
              track?.attach(
                document.getElementById("screen-video") as HTMLVideoElement
              );
            }, 1000);
          } else {
            console.log(
              "Attaching remote track",
              document.getElementById("video-" + participant?.sid)
            );
            // this.attachRemoteTrack(track, participant);
            // setTimeout(() => {
            if (track?.isEnabled) {
              track?.attach(
                document.getElementById(
                  "video-" + participant?.sid
                ) as HTMLVideoElement
              );
            }
            // }, 2000);
          }
        }, 2000);
      }

      if (track.kind === "audio") {
        //this.attachRemoteTrack(track, participant);
        track?.attach(
          document.getElementById(
            "video-" + participant?.sid
          ) as HTMLVideoElement
        );
      }
    });
    participant.on(PROCONF_EVENTS.TRACK_UNSUBSCRIBED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_UNSUBSCRIBED", participant, track);
      store.dispatch({
        type: "UPDATE_PARTICIPANT",
        payload: {
          ...participant,
          tracks: Array.from(participant?.tracks?.values()),
          audioTracks: Array.from(participant?.audioTracks?.values()),
          videoTracks: Array.from(participant?.videoTracks?.values()),
          isLocal: false,
        },
      });
      setTimeout(() => {
        if (track.kind === "video") {
          if (track?.getVideoType() === "desktop") {
            store.dispatch({
              type: "SET_REMOTE_SS",
              payload: false,
            });
          }
        }
      }, 2000);

      if (track.kind === "audio") {
        // track.detach();
      }
    });
    participant.on(PROCONF_EVENTS.TRACK_UNPUBLISHED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_UNPUBLISHED", participant, track);
    });
    participant.on(PROCONF_EVENTS.TRACK_PUBLISHED, (track: Track) => {
      console.log("ProCONFservice:::TRACK_PUBLISHED", participant, track);
    });
  }

  // Remote track events
  private bindTrackEvents(participant: Participant, track: Track) {
    console.log("ProCONF service:::bindTrackEvents", participant, track);
    track.on(PROCONF_EVENTS.ENABLED, (data: Track) => {
      console.log("ProCONF service:::track enabled", data);
      this.updateParticipantInStore(participant);
      if (track?.kind === "video") {
        // Handle remote video track enabled
        if (participant?.sid !== this.localParticipant?.sid) {
          setTimeout(() => {
            if (track?.getVideoType() === "camera" && track?.isEnabled) {
              track?.attach(
                document.getElementById(
                  "video-" + participant?.sid
                ) as HTMLVideoElement
              );
            }
          }, 1000); // wait for UI element to be added to DOM
        }
      }
      if (track.kind === "audio") {
        // Handle audio track enabled
      }
    });
    track.on(PROCONF_EVENTS.DISABLED, (data: Track) => {
      console.log("ProCONF service:::Track Disabled event", data);
      this.updateParticipantInStore(participant);
      if (track.kind === "video") {
        setTimeout(() => {
          // Detach remote track
          if (participant?.sid !== this.localParticipant?.sid) {
            track.detach();
            if (track?.getVideoType() === "desktop") {
              store.dispatch({
                type: "SET_REMOTE_SS",
                payload: false,
              });
            }
          }
        }, 2000);
      }
      if (track.kind === "audio") {
        // Handle audio track disabled
      }
    });
    track.on("screenShareStopped", (data: Track) => {
      console.log("screenShareStopped event received...", data);
    });

    track.on(PROCONF_EVENTS.STOPPED, (data: any) => {
      console.log("ProCONF service:::track stopped event", data);
      this.updateParticipantInStore(participant);
      if (track.kind === "video") {
        // Detach remote track
        if (participant?.sid !== this.localParticipant?.sid) {
          track.detach();
          if (track?.getVideoType() === "desktop") {
            store.dispatch({
              type: "SET_REMOTE_SS",
              payload: false,
            });
          }
        }
      }
    });
  }

  // Create tracks from the selected audio and video devices(used when user refreshes app while in call)
  public async createTracksAndJoin(
    roomOptions: any,
    startMeeting: boolean = true
  ) {
    const micId = store.getState()?.ToolbarReducer?.selectedMic?.deviceId;
    const cameraId = store.getState()?.ToolbarReducer?.selectedCamera?.deviceId;
    const micmute = store.getState()?.ToolbarReducer?.audioMute;
    const videomute = store.getState()?.ToolbarReducer?.videoMute;
    if (micId && cameraId) {
      await this.createTracks({
        micDeviceId: micId,
        cameraDeviceId: cameraId,
      }).then(async (tracks) => {
        this.tracks = tracks;
        if (micmute) {
          tracks.filter((t: Track) => t.kind === "audio")[0]?.disable();
        }
        if (videomute) {
          tracks.filter((t: Track) => t.kind === "video")[0]?.disable();
        }
        if (startMeeting) {
          this.room = await ProConfService.proConfManager?.startMeeting(
            roomOptions,
            tracks
          );
        } else {
          this.room = await ProConfService.proConfManager?.joinMeeting(
            roomOptions,
            tracks
          );
        }
      });
    }
  }

  // Mute audio
  public async muteAudio() {
    const audiotrack = this.tracks?.find(
      (track: Track) => track?.kind === "audio"
    );
    await audiotrack?.disable();
  }

  // Unmute audio
  public async unmuteAudio() {
    const audiotrack = this.tracks?.find(
      (track: Track) => track?.kind === "audio"
    );
    await audiotrack?.enable();
  }

  // Mute video
  public async muteVideo() {
    const videotrack = this.tracks?.find(
      (track: Track) => track.kind === "video"
    );
    await videotrack?.disable();
  }

  // Unmute video
  public async unmuteVideo() {
    const videotrack = this.tracks.find(
      (track: Track) => track.kind === "video"
    );
    await videotrack?.enable().then(() => {
      setTimeout(() => {
        this.attachLocalTracks(this.room);
      }, 1000);
    });
  }

  // Leave meeting
  public async leaveCall() {
    console.log("end call for room:", this.room);
    this.isCallInProgress = false;
    this.room?.disconnect();
    await this.cleanUp();
    this.redirect();
  }

  // End meeting for all participants by moderator
  public async endMeeting() {
    console.log("End meeting for all participants by moderator:");
    this.isCallInProgress = false;
    if (this.room) {
      this.room?.end();
    }
    await this.cleanUp();
    this.redirect();
  }

  // Get meeting list
  public async getMeetingList() {
    return await ProConfService.proConfManager?.getMeetingList();
  }

  // Get call summary
  public async viewCallSummary(roomId: string) {
    console.log("viewCallSummary sdk: ", roomId);
    return await ProConfService.proConfManager?.getCallSummary(roomId);
  }

  // Get call catchup
  public async getCallCatchUp(roomId: string) {
    console.log("Call catchup sdk: ", roomId);
    return await ProConfService.proConfManager?.getCallCatchUp(roomId);
  }

  // Get call status
  public async getCallStatus(roomId: string) {
    console.log("Call status: roomId", roomId);
    return await ProConfService.proConfManager?.getCallStatus(roomId);
  }

  // Start screen sharing
  public async startScreenShare() {
    if (
      typeof navigator === "undefined" ||
      !navigator.mediaDevices ||
      !navigator.mediaDevices.getDisplayMedia
    ) {
      return Promise.reject(new Error("getDisplayMedia is not supported"));
    }
    // User has clicked on screen share button to open screen selection dialog
    this.screenShareDialogOpen = true;
    try {
      return await navigator.mediaDevices
        .getDisplayMedia({
          video: true,
        })
        .then((stream) => {
          return ProConfService.proConfManager
            ?.createTrackFromMediaStreamTrack(stream.getVideoTracks()[0])
            .then((tracks) => {
              this.screenShareDialogOpen = false;
              const screenTrack = tracks[0];
              this.screenTrack = screenTrack;

              // Publish the screen track.
              this.room?.localParticipant.publishTrack(screenTrack);

              // When screen sharing is stopped, unpublish the screen track.
              screenTrack.on("stopped", async () => {
                console.log("Screen track stopped", screenTrack);
                this.screenTrack = null;
              });

              stream.getVideoTracks()[0].addEventListener("ended", async () => {
                // We come here only if screen share is stopped using browser's button
                if (screenTrack?.getVideoType() === "desktop") {
                  console.log("Screensharing has ended 'ended' event");
                  store.dispatch({ type: ON_SCREEN_SHARE_TOGGLE });
                  await screenTrack?.disable();
                  await this.localParticipant?.unpublishTrack(screenTrack);
                  screenTrack.stop();
                  this.screenTrack = null;
                  this.screenShareDialogOpen = false;
                }
              });
            })
            .catch((error) => {
              console.log("in 1st error: ", error);
              onScreenSharing(true);
              return Promise.reject(new Error("Error in getDisplayMedia"));
            });
        });
    } catch (error: any) {
      console.log("in 2nd error: ", error);
      onScreenSharing(true);
      console.error("Error in getDisplayMedia", error);
    }
  }

  // Stop screen share button click
  stopScreenShare = async (st?: Track) => {
    console.log("Stopscreen button click", this.screenTrack);
    const track = st || this.screenTrack;
    if (track && track.sid) {
      await track?.disable();
      await this.localParticipant?.unpublishTrack(track);
      track.stop();
      this.screenShareDialogOpen = false;
    }
    this.screenTrack = null;
  };

  // Toggle Noise Cancellation
  public toggleNoiseCancellation(enable: boolean) {
    try {
      this.room?.setNoiseSuppression(enable);
    } catch (error) {
      console.error(
        "ProConfService::: error in toggleNoiseCancellation:",
        error
      );
      // TODO: handle error, reset noise cancellation state etc.
    }
  }

  // Attach local tracks to DOM
  attachLocalTracks(room: Room | null | undefined = this.room) {
    if (!room) return;
    let localvideoTrack = Array.from(
      room?.localParticipant?.videoTracks?.values() as Track[]
    )[0];
    if (
      localvideoTrack?.isEnabled &&
      localvideoTrack?.getVideoType() === "camera"
    ) {
      setTimeout(() => {
        if (document.getElementById("video-" + room?.localParticipant?.sid))
          localvideoTrack?.attach(
            document.getElementById(
              "video-" + room?.localParticipant?.sid
            ) as HTMLVideoElement
          );
      }, 1000);
    }
  }

  // Attach remote tracks to DOM
  attachRemoteTrack(track: Track, participant: Participant) {
    track?.attach(
      document.getElementById("video-" + participant.sid) as HTMLVideoElement
    );
  }

  // Update participant tracks in store
  updateParticipantInStore(participant: Participant) {
    store.dispatch({
      type: "UPDATE_PARTICIPANT",
      payload: {
        ...participant,
        tracks: Array.from(participant?.tracks?.values()),
        audioTracks: Array.from(participant?.audioTracks?.values()),
        videoTracks: Array.from(participant?.videoTracks?.values()),
      },
    });
  }

  // Store local participant in store
  storeLocalParticipant() {
    store.dispatch({
      type: "SET_LOCAL_PARTICIPANT",
      payload: {
        ...this.localParticipant,
        tracks: Array.from(this.localParticipant?.tracks?.values()),
        audioTracks: Array.from(this.localParticipant?.audioTracks?.values()),
        videoTracks: Array.from(this.localParticipant?.videoTracks?.values()),
      },
    });
    store.dispatch({
      type: "ADD_PARTICIPANT",
      payload: {
        ...this.localParticipant,
        tracks: Array.from(this.localParticipant?.tracks?.values()),
        audioTracks: Array.from(this.localParticipant?.audioTracks?.values()),
        videoTracks: Array.from(this.localParticipant?.videoTracks?.values()),
      },
    });
  }

  // Set sink id of remote audio element
  setSinkId(element?: any, speaker?: any) {
    try {
      const selectedSpeaker =
        speaker || store.getState().ToolbarReducer?.selectedSpeaker;
      console.log("Setting sinkid of: ", selectedSpeaker);
      if (selectedSpeaker && selectedSpeaker?.deviceId) {
        if (typeof element?.sinkId !== "undefined") {
          applyAudioOutputDeviceSelection(selectedSpeaker?.deviceId, element);
        }
      }
    } catch (error) {
      console.error("Error in setting sink id of remote audio element", error);
    }
  }

  // Stop all Local tracks
  async clearTracks() {
    if (this.tracks) {
      this.tracks.forEach(async (track: Track) => {
        await track?.stop();
      });
    }
    if (this.screenTrack && this.screenTrack?.sid) {
      await this.screenTrack?.stop();
    }
  }

  // Reset everything after call gets over
  async cleanUp() {
    console.log("Cleanup function called");
    await this.clearTracks();
    this.room = null;
    this.localParticipant = null;
    this.tracks = [];
    store.dispatch({ type: CALL_IN_PROGRESS, payload: false });
    store.dispatch({ type: CLEAR_PARTICIPANTS });
    store.dispatch({ type: CLEAR_ROOM });
    store.dispatch({ type: RESET_TOOLBAR_STATE });
    store.dispatch({ type: SET_REMOTE_SS, payload: false });
    store.dispatch({ type: CLEAR_INCALL_REDUCER });
  }

  // Redirect to landing page/login pg
  redirect() {
    console.log("Room ended...redirecting");
    setTimeout(() => store.dispatch({ type: SET_ROOM_ENDED, payload: true }));
  }

  // Create default tracks without device selection
  async createDefaultTracks() {
    this.tracks = await ProConfService.proConfManager?.createLocalTracks({
      devices: ["audio", "video"],
    });
  }
}

const proConfService = new ProConfService();

export default proConfService;
