import React from "react";


//Constants
import { ROUTES } from "./RouteConstants";

//Components
import LandingPageContainer from "../Components/LandingPage/LandingPageContainer";
import CreateMeetingContainer from "../Components/MeetingPreview/MeetingPreviewContainer";
import CallScreenContainer from "../Components/CallScreen/CallScreenContainer";

//Components


const routes = [
    {
        path: ROUTES.LANDING_PAGE,
        exact: true,
        Component: () => (
            < LandingPageContainer />
        ),
    },
    {
        path: ROUTES.CREATE_MEETING,
        exact: true,
        Component: () => (
            < CreateMeetingContainer />
        ),
    },
    {
        path: ROUTES.IN_CALL,
        exact: true,
        Component: () => (
            < CallScreenContainer />
        ),
    },
]

export default routes;