import { FunctionComponent } from "react";
import { getTranslation } from "../../Resources/Localization/i18n";
import "./MeetingCatchup.scss";
import CrossIcon from "../../Resources/Images/icadd21@2x.png";

export type MeetingCatchupType = {
  className?: string;
  onClose?: () => void;
  onJoin: () => void;
  text: string;
  isProceedClicked: boolean;
};

const MeetingCatchup: FunctionComponent<MeetingCatchupType> = ({
  className = "",
  onJoin,
  text,
  isProceedClicked
}) => {
  return (
    <div className={`meeting-catchup ${className}`}>
      <div className="meeting-catchup-parent">
        <b className="meeting-catchup1">{getTranslation("meetingCatchup")}</b>
        {/* <button className="buttonsignin" onClick={onClose}>
          <img className="icadd2-icon" alt="close" src={CrossIcon} />
        </button> */}
      </div>
      <div className="oops-youre-late-catch-up-on-parent">
        <div className="oops-youre-late">{getTranslation("briefSummary")}</div>
        <div className="nunc-at-vitae-container pr-2">
          <p className="nunc-at-vitae">{text}</p>
          <p className="nunc-at-vitae">&nbsp;</p>
        </div>
      </div>
      <div className="join-call-wrapper">
        <b className="join-call">Join Call</b>
      </div>
      <div className="actions1">
        <button className={isProceedClicked ? "proceedbutton1 disabled-icon": "proceedbutton1"} onClick={() => onJoin()}>
          <b className="proceed">{getTranslation("proceedCall")}</b>
        </button>
      </div>
    </div>
  );
};

export default MeetingCatchup;
