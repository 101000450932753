import { FunctionComponent } from "react";
import "./NameTag.scss";
import MicOffNameTag from "../../Resources/InCallImages/MicOffNameTag.svg";
import MicOnNameTag from "../../Resources/InCallImages/MicOnNameTag.svg";

export type NameTagType = {
  className?: string;
  participantName?: string;
  showMicOffIcon?: boolean;
};

const NameTag: FunctionComponent<NameTagType> = ({
  className = "",
  participantName,
  showMicOffIcon,
}) => {
  return (
    <div className={`nametag ${className}`}>
      {showMicOffIcon && (
        <img className="micoff-icon" alt="MicOffNameTag" src={MicOffNameTag} />
      )}
      {!showMicOffIcon && (
        <img className="micon" alt="MicOnNameTag" src={MicOnNameTag} />
      )}
      <div className="name">{participantName}</div>
    </div>
  );
};

export default NameTag;
