import { Spin, Space } from 'antd';
import { useEffect } from 'react';
 
import "./LoadingSpinner.scss"
/**
 *
 * @returns jsx to show the spinner
 */
const LoadingSpinner = () => {
 
    // useEffect(() => {
    //     document.body.style.overflow = 'hidden';
    //     return (() => {
    //         document.body.style.overflow = 'auto';
    //     })
    // })
 
    return (
        <Space size="middle">
            <Spin size="large" spinning fullscreen />
        </Space>
    );
};
 
export default LoadingSpinner;