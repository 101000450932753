//Languages supported in the application
export const LANGUAGE = {
  ENGLISH: {
    name: "en",
    value: "English",
  },
  SPANISH: {
    name: "es",
    value: "Spanish",
  },
};


//HTTP methods for REST apis
export const METHOD = {
  HTTP: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
  },
};

//HTTP API status for REST apis
export const API_STATUS = {
  SUCCESS: 200,
  SUCCESS_NO_CONTENT: 204,
  NETWORK_ERROR: 600,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  UNSUPPORTED_MEDIA: 415,
  INTERNAL_SERVER: 500,
  NOT_FOUND: 404,
};

// Remove this after pipeline setup

// Uncomment For QA build
// export const CONNECTION_DETAILS = {
//   APP_SERVER_DOMAIN: 'https://qa.proconf.info/as',
//   MUC_DOMAIN: 'conference.',
//   SERVICE_URL: '//staging.proconf.info/http-bind',
// };

// Uncomment For automation build
// export const CONNECTION_DETAILS = {
//   APP_SERVER_DOMAIN: 'https://automation-admin.proconf.info/as',
//   MUC_DOMAIN: 'conference.',
//   SERVICE_URL: '//automation.proconf.info/http-bind',
// };

// For dev build (local)
export const CONNECTION_DETAILS = {
  APP_SERVER_DOMAIN: 'https://dev-app.proconf.info/as',
  MUC_DOMAIN: 'conference.',
  SERVICE_URL: '//springdev.proconf.info/http-bind',
};


export const CONFIGURATIONS = {
  AUDIO_CALL: "audio_call",
  AUTO_CENTERING: "auto_centering",
  CALL_SUMMARY: "call_summary",
  CATCHUP_MEETING: "catchup_meeting",
  CLIENT_SIDE_RECORDING: "client_side_recording",
  DESKTOP_STREAMING: " desktop_streaming",
  LIVE_CHAT: "live_chat",
  MODERATOR_CONTROL: "moderator_control",
  SCREEN_SHARING: "screen_sharing",
  SERVER_SIDE_RECORDING: "server_side_recording",
  TRANSCRIPT: "transcript",
  VIDEO_CALL: "video_call",
  WAITING_ROOM: "waiting_room",
};
