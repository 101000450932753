import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import LandingPage from "./LandingPage";

//Custom Components
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Actions
import {
  getMeetingList,
  viewCallSummary,
} from "../../Redux/Actions/MeetingListAction";
import { logoutAction } from "../../Redux/Actions/LoginAction";
import { clearMeetingDataFromStore } from "../../Redux/Actions/StoreAction";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import {
  CLEAR_NOTIFICATIONS,
  SET_CREATE_MEETING,
} from "../../Redux/ReduxConstants";

//Store
import { store } from "../../Redux/store";

/**
 *
 * @returns jsx for Meeting list/ landing page component
 * this page wiil be displayed after Moderator Login
 */
export default function LandingPageContainer() {
  const navigate = useNavigate();

  //state variables
  const [isLoading, setIsLoading] = useState(false);
  const [meetingList, setMeetingList] = useState<any[]>([]);
  const [summary, setCallSummary] = useState({});
  const isInitDone = useSelector(
    (state: any) => state.ProConfReducer.isInitDone
  );

  /**
   * @method getMeetingsList
   * @description get the list of Meeting created by logged-in user
   * @author Ruchika Phalke <ruchika.phalke@springct.com>
   * @returns list of previous meetings created by logged-in user/Moderator
   */
  function getMeetingsList() {
    setIsLoading(true);

    getMeetingList()
      .then((list) => {
        setMeetingList(list); // Update state with fetched data
      })
      .catch((error) => {
        // Handle error as needed
      });
  }

  /**
   * @method joinProConfMeeting
   * @param uniqueId meeting's unique id
   * @description User can get the the details of the past meetings
   * if summary is available (transcription needs to be ON)
   * @author Ruchika Phalke <ruchika.phalke@springct.com>
   * @returns the call summary of a specified/selected meeting
   */
  async function getCallSummary(roomId: string) {
    setIsLoading(true);
    let summary = await viewCallSummary(roomId);
    // @ts-ignore
    setCallSummary(summary);
    setIsLoading(false);
  }

  /**
   * @method createMeetingButton
   * @description Go to the meeting preview page for devices selection
   * Only Moderator can create meetings
   * @author Ruchika Phalke <ruchika.phalke@springct.com>
   */
  async function createMeetingButton() {
    navigate(ROUTES.CREATE_MEETING);
  }

  /**
   * @method joinProConfMeeting
   * @description Go to the meeting preview page for devices selection
   * Moderator will be joined as a guest for existing meetings
   * or new meeting created by other moderator
   * @author Ruchika Phalke <ruchika.phalke@springct.com>
   */
  async function joinProConfMeeting() {
    store.dispatch({
      type: SET_CREATE_MEETING,
      payload: false,
    });
    navigate(ROUTES.CREATE_MEETING);
  }

  const onLogoutClick = async () => {
    await logoutAction();
    navigate("/");
  };

  useEffect(() => {
    clearMeetingDataFromStore();
    store.dispatch({ type: CLEAR_NOTIFICATIONS });
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    // Get meeting list after init done
    if (isInitDone) {
      getMeetingsList();
    }
  }, [isInitDone]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 2000);
  }, [isLoading]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <LandingPage
        meetingList={meetingList}
        getCallSummary={(uniqueId: string) => getCallSummary(uniqueId)}
        // @ts-ignore
        summary={summary}
        createMeetingButton={createMeetingButton}
        joinMeeting={joinProConfMeeting}
        onLogoutClick={onLogoutClick}
        // @ts-ignore
        setCallSummary={setCallSummary}
      />
    </>
  );
}
