import { FunctionComponent } from "react";
import "./MoreActionsWeb.scss";
import RecordingOptions from "../RecordingOptions/RecordingOptions";
import TranscriptMenuIcon from "../../Resources/InCallImages/TranscriptMenuIcon.svg";
// import StartRecordingMenuIcon from "../../Resources/InCallImages/StartRecordingMenuIcon.svg";
// import StopRecordingMenuIcon from "../../Resources/InCallImages/StopRecordingMenuIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";
import { CONFIGURATIONS } from "../../Constants/AppConstants";
import { useSelector } from "react-redux";

export type MoreActionsWebType = {
  className?: string;
  activeRecording?: boolean;
  onClose?: () => void;
  toggleTranscriptPanel?: () => void;
  toggleRecording?: () => void;
};

const MoreActionsWeb: FunctionComponent<MoreActionsWebType> = ({
  className = "",
  activeRecording,
  onClose = () => {},
  toggleTranscriptPanel = () => {},
  toggleRecording = () => {},
}) => {
  const adminConfig = useSelector(
    (state: any) => state.LoginReducer?.configurations
  );

  const callActions = (callback: () => void) => {
    callback();
    onClose();
  };

  return (
    <div className={`moreactionsweb ${className}`}>
      {CONFIGURATIONS.TRANSCRIPT in adminConfig && (
        <RecordingOptions
          icon={TranscriptMenuIcon}
          label={getTranslation("transcription")}
          onClick={() => callActions(toggleTranscriptPanel)}
        />
      )}
      {/* {CONFIGURATIONS.CLIENT_SIDE_RECORDING in adminConfig &&
        (!activeRecording ? (
          <RecordingOptions
            icon={StartRecordingMenuIcon}
            label={getTranslation("startRecording")}
            onClick={() => callActions(toggleRecording)}
          />
        ) : (
          <RecordingOptions
            icon={StopRecordingMenuIcon}
            label={getTranslation("stopRecording")}
            onClick={() => callActions(toggleRecording)}
          />
        ))} */}
    </div>
  );
};

export default MoreActionsWeb;
