import { InCallState } from "../StateTypes/StateTypes";
import { InCallActionTypes } from "../ActionTypes/InCallActionTypes";
import { SET_TRANSCRIPTIONS, CLEAR_INCALL_REDUCER, SET_ROOM_ENDED } from "../ReduxConstants";

// Define the initial state for the room reducer
const initialState: InCallState = {
  transcriptions: [],
  roomEnded: false,
};

// Reducer function to handle state changes based on actions
const InCallReducer = (
  state = initialState,
  action: InCallActionTypes
): InCallState => {
  switch (action.type) {
    case SET_TRANSCRIPTIONS:
      return {
        ...state,
        transcriptions: [...state.transcriptions, action.payload],
      };

    case CLEAR_INCALL_REDUCER:
      return {
        ...state,
        transcriptions: [],
        roomEnded: false,
      };

    case SET_ROOM_ENDED:
      return {
        ...state,
        roomEnded: action.payload,
      };
    default:
      return state;
  }
};

export default InCallReducer;
