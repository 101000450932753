import { LoginState } from "../StateTypes/StateTypes";
import { LoginActionTypes } from "../ActionTypes/LoginActionTypes"
import {
  LOGOUT,
  SET_AUTH_TOKEN,
  SET_AVAILABLE_CONFIGURATIONS,
  SET_PROFILE_NAME
} from "../ReduxConstants";

// Define the initial state for the ProConf reducer
const initialState: LoginState = {
  token: '',
  configurations: {},
  userName: '',
};

// Reducer function to handle state changes based on actions
const LoginReducer = (
  state = initialState,
  action: LoginActionTypes
): LoginState => {

  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, token: action.payload };

    case SET_AVAILABLE_CONFIGURATIONS:
      return { ...state, configurations: action.payload }

    case SET_PROFILE_NAME:
      return { ...state, userName: action.payload }

    case LOGOUT:
      return initialState

    default:
      return state;
  }
  
};

export default LoginReducer;
